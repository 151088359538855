import React, { useState }from 'react';
import {Box, DataTable, Grommet} from 'grommet';
import { grommet } from 'grommet/themes';

const FridgeList = (props) => {
  const [sort, setSort] = useState({
    property: 'name',
    direction: 'desc'
  })
  return (
    <Grommet theme={grommet} full>
  <Box pad="large">
    <DataTable 
    columns={
      [
        {
          property: 'name',
          header: 'Fridge Name',
          primary: true,
        }
      ]
    }
    data={props.fridges}
    sort={sort}
    onSort={setSort}
    // paginate
    // step={10}
    />
  </Box>
  </Grommet>
  )
};

export default FridgeList;
