const config = {
  google: {
    apiKey: 'AIzaSyCOvDGXIuekFUUzSPh5l1940wCB657NudI'
  },
  defaultCenter: {
    lat: 40.75,
    lng: -73.9,
  },
  defaultZoom: 12
}



export default config;